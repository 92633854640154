@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
	font-family: 'Bookmania Semibold';
	src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.manage-consent {
	&__container {
		height: 100vh;
		padding-top: 71px;
		overflow: auto !important;
		background: $color-light-gray;
	}

	&__more {
		cursor: pointer;
	}

	.empty-description {
		width: 36px;
	}

	&__header {
		background: $color-lightest-gray;
		min-height: 307px;
		padding-top: 105px;

		@include screen-sm {
			min-height: 159px;
			padding-top: 0;
		}

		.container {
			@include screen-sm {
				display: flex;
				flex-direction: column;
				height: 100%;
				min-height: 159px;
				justify-content: center;
				padding-left: 32px;
				padding-right: 32px;
			}
		}

		&-title {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			letter-spacing: -0.54px;
			margin-bottom: 24px;
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
		}
	}

	&__items {
		background: #fff;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		margin-top: 49px;

		@include screen-sm {
			width: calc(100% - 64px);
		}
	}

	&__content {
		margin-top: 49px;
	}

	&__form {
		max-width: 460px;
		width: 100%;

		.form-group {
			width: 100%;
		}
	}

	&__subtitle {
		font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
		letter-spacing: -0.54px;
	}

	&__subtext {
		color: $color-font-gray;
		margin-bottom: 24px;
	}

	&__item {
		display: flex;
		flex-direction: column;
		padding: 29px 50px 38px 29px;
		border-bottom: 1px solid $color-lightest-gray;

		@include screen-sm {
			padding: 24px 0;
		}

		&-title {
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 3px;
		}

		&-placeholder {
			width: 25px;
			height: 25px;
			border-radius: 50%;
			background: #dfe4ee;
			display: block;
			margin-right: 10px;
		}

		&-logo {
			max-height: 30px;
			height: 30px;
			max-width: 60px;
			object-fit: contain;
			margin-right: 5px;
		}

		&-text {
			font-size: 14px;
			color: #292e31;
			max-width: 300px;
		}

		&-more {
			background: $color-light-gray;
			border-radius: 4px;
			padding: 22px 24px;
			color: #292e31;
			font-size: 14px;
			margin-top: 24px;

			@include screen-sm {
				padding: 22px 14px;
			}

			.ReactTable {
				border: none;
				margin-top: 50px;
				margin-bottom: 70px;

				.rt-thead {
					box-shadow: none;
				}

				.rt-th {
					border: none;
					text-align: left;
					padding: 0;
					margin-bottom: 23px;
				}

				.rt-td {
					padding-left: 0;
				}
			}
		}
	}
}

.signed-dpa {
	padding-top: 71px;
	overflow: auto !important;
	background: $color-light-gray;

	&.-has-zoom {
		zoom: 120%;
	}

	&__banner {
		height: 11px;
		background: #71e4ef;
		position: absolute;
		top: 0;
		width: 100%;
		left: 0;
	}

	&__signature {
		width: 200px;
	}

	&__doc {
		background: #fff;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		margin-top: 60px;
		margin-bottom: 60px;
		padding: 45px !important;

		@include screen-sm {
			margin-top: -11px;
		}

		&-submit {
			justify-content: flex-end;

			@include screen-sm {
				justify-content: flex-start;
			}
		}

		&-zoom {
			position: fixed;
			bottom: 24px;
			right: 24px;
			display: flex;
			flex-direction: column;

			span {
				background: #fff;
				box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
				height: 36px;
				width: 36px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 4px;
				margin-bottom: 4px;
				cursor: pointer;
			}
		}

		&-title {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			letter-spacing: -0.2px;
			font-size: 28px;
			margin-top: 44px;
			margin-bottom: 55px;
		}

		p {
			font-size: 14px;
			color: $color-font-dark;
		}

		&-content {
			margin-top: 86px;
		}

		&-company {
			max-width: 323px;
			width: 100%;

			input {
				border: none !important;
				background: rgba(235, 166, 1, 0.2);
			}
		}
	}
}
