@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

.not-found {
  height: 100vh;
  background: $color-lightest-gray;

  &__content {
    padding-top: 71px;
  }

  &__item {
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 663px;
    margin-top: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.16px;
      color: $color-brand-1;
      margin-top: 54px;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 29px;
      text-align: center;
    }

    &-home {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;

      &:hover {
        color: $color-brand-1;
      }
    }
  }
}