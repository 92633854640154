@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
  font-family: 'Bookmania Semibold';
  src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.legalsite {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: $color-light-gray;

  &__container {
    overflow-x: hidden;
  }

  &__nav {
    background: rgb(255, 255, 255);
    box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 72px;
    padding-right: 72px;
    position: fixed;
    width: 100%;
    z-index: 11;

    &.-page-scroll {
      position: sticky;
      top: 0;
    }

    &-link {
      font-size: 14px;
      color: #7450c8;
      font-size: 14px;

      &:hover {
        color: #7450c8;
        text-decoration: none;
      }

      &.-submit {
        border-radius: 4px;
        border: 1.5px solid rgb(116, 80, 200);
        height: 36px;
        width: 136px;
        padding: 8px 12px;
      }
    }

    .-home {
      @include screen-sm {
        display: none;
      }
    }

    @include screen-sm {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__logo {
    background: $color-brand-1;
    opacity: 0.65;
    height: 45px;
    width: 45px;
    display: block;
    border-radius: 50%;
    margin-right: 24px;
  }

  &__logo-container {
    height: 45px;
    display: block;
    margin-right: 24px;
    overflow: hidden;
    max-width: 150px;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  &__brand {
    font-size: 28px;
    font-weight: bold;
    color: $color-brand-1;
    opacity: 0.65;
  }

  &__link {
    font-size: 14px;
    color: $color-brand-1;
    cursor: pointer;
  }

  &__welcome {
    background: $color-lightest-gray;
    min-height: 430px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 72px;

    @include screen-sm {
      min-height: 159px;
      justify-content: center;
    }

    &-title {
      margin-top: 73px;
      font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
      margin-bottom: 24px;
      width: 90% !important;
      white-space: pre-wrap;

      @include screen-sm {
        margin-top: 10px;
      }
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      text-align: center;
      padding: 0 15px;
      width: 90%;
      white-space: pre-wrap;
    }
  }

  &__options {
    background: $color-light-gray;

    &-content {
      justify-content: center;
      top: -140px;
      position: relative;
      margin-bottom: -140px;

      @include screen-sm {
        margin-bottom: 0;
        top: 0;
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    &-info {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      min-height: 258px;
      max-width: 219px !important;
      min-width: 219px !important;
      margin-right: 172px;
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin-bottom: 20px;

      @include screen-sm {
        max-width: 100% !important;
        min-width: 100% !important;
        min-height: 124px;
      }

      svg {
        margin-top: 32px;
        min-height: 31px;
        margin-bottom: 25px;

        @include screen-sm {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      @include screen-md {
        margin-right: 20px;
        margin-top: 30px;
      }

      @include screen-xs {
        margin-right: 10px;
        margin-left: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-subtitle {
      font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
      font-size: 28px;
      margin-bottom: 24px;
      text-align: center;
      margin-top: -66px;
      padding-top: 90px;
      white-space: pre-wrap;
      padding-left: 32px;
      padding-right: 32px;

      &-text {
        font-size: 18px;
        text-align: center;
        max-width: 695px;
        margin-left: auto;
        margin-right: auto;
        color: $color-font-gray;
        margin-bottom: 64px;
        padding: 0 15px;
        white-space: pre-wrap;

        @include screen-sm {
          padding-left: 32px;
          padding-right: 32px;
          max-width: 100%;
        }
      }
    }
  }

  &__info {
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 24px;
      white-space: pre-wrap;
      width: 100%;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 21px;
      white-space: pre-wrap;
      width: 100%;
    }

    &-more {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      cursor: pointer;
      white-space: pre-wrap;
      width: 100%;

      &:hover {
        color: $color-brand-1;
      }
    }
  }
}

.customization-site {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: calc(100vh - 90px);
  overflow-y: auto;
  padding-bottom: 40px;
  padding-right: 20px;
  margin-bottom: 40px;
  overflow-x: hidden;

  &__item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 214px;
    max-width: 999px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    flex-direction: column;

    @include screen-sm {
      width: calc(100% - 66px);
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 0;
      white-space: pre-wrap;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      max-width: 338px;
      margin-top: 24px;
      margin-bottom: 24px;
      text-align: center;
      white-space: pre-wrap;
    }

    &-btn {
      width: 136px !important;
      font-weight: bold !important;
      background: rgb(116, 80, 200);
      border-radius: 4px;
      height: 36px;
      width: 136px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: pre-wrap;
    }
  }

  &__btn {
    width: 136px !important;
    font-weight: bold !important;
    background: rgb(116, 80, 200);
    border-radius: 4px;
    height: 36px;
    width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__welcome {
    &-title {
      font-size: 56px;
      font-weight: 600;
      letter-spacing: -0.54px;
      text-align: center;
      padding-top: 70px;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      text-align: center;
      max-width: 470px;
      margin-left: auto;
      margin-right: auto;
    }

    &-section {
      min-height: 430px;
      background: $color-lightest-gray;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__info {
    margin-top: -16px;

    &-privacy {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      min-height: 258px;
      max-width: 219px !important;
      min-width: 219px !important;
      margin-right: 20px;
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin-bottom: 20px;

      svg {
        margin-top: 32px;
        min-height: 31px;
        margin-bottom: 25px;
      }

      @include screen-md {
        margin-right: 20px;
        margin-top: 30px;
      }
    }

    &-subtitle {
      font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
      font-size: 28px;
      letter-spacing: -0.2px;
      text-align: center;
      margin-top: 90px;
      margin-bottom: 24px;
    }

    &-details {
      font-size: 18px;
      color: $color-font-gray;
      max-width: 695px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 67px;

      &-item {
        width: 163px;
        min-height: 200px;
        text-align: center;
        margin-left: 72px;
        margin-right: 72px;

        @include screen-sm {
          width: 100%;
          margin-left: 33px;
          margin-right: 33px;
          margin-bottom: 39px;
          min-height: auto;
        }
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 24px;
      margin-top: 24px;
      white-space: pre-wrap;

      @include screen-sm {
        margin-top: 5px !important;
        margin-bottom: 5px;
      }
    }

    &-text {
      font-size: 16px;
      color: $color-font-gray;
      margin-bottom: 24px;
      white-space: pre-wrap;
    }

    &-more {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      white-space: pre-wrap;
    }

    &-content {
      justify-content: center;
      position: relative;
      top: -140px;
      margin-bottom: -140px;
    }
  }
}
