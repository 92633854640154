//-------------------------------------//
// GRID

$grid-gutter: 15px;


//-------------------------------------//
// MEDIA QUERIES

$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;


//-------------------------------------//
// COLORS

// colors: base font
$color-font-base: #333;
$color-font-gray: #969696;
$color-font-light: #B0BAC9;

// colors: brand
$color-brand-1: #7450C8; // purple
$color-brand-2: #4f2DB8; // dark purple
$color-brand-3: #EBA601; // yellow
$color-brand-4: #1DDCC0; // lighter green
$color-brand-5: #F86B67; // coral

// colors: base background
$color-body-bg: #fff;
$color-font-dark: #292e31;

// colors: links
$color-link: #7450C8;
$color-link-hover: lighten($color-link, 15%);
$color-menu-active: $color-brand-2;

// colors: lightest-gray
$color-lightest-gray: #DFE4EE;

// colors: light-gray
$color-light-gray: #F4F6FC;
$color-light-gray2: #828d99;

// colors: dark-gray
$color-dark-gray: #333;

// colors: border
$color-border: #f0f1f2;

//-------------------------------------//
// FONTS

// fonts: weights
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-bold: 700;


//-------------------------------------//
// SPACING

$spacing: 20px;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;
$spacing-half: $spacing / 2;
