@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
	font-family: 'Bookmania Semibold';
	src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.data-access {
	// height: 100vh;
	padding-top: 71px;
	overflow: auto !important;
	background: $color-light-gray;

	&__header {
		background: $color-lightest-gray;
		height: 307px;
		padding-top: 105px;

		&-title {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			letter-spacing: -0.54px;
			margin-bottom: 24px;
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
		}
	}

	&__container {
		background: #fff;
		border-radius: 4px;
		height: 374px;
		margin-top: 48px;
		margin-bottom: 224px;
		justify-content: center;
		align-items: center;
    display: flex;
    flex-direction: column;

    img {
      width: 132px;
      margin-bottom: 48px;
    }
  }
  
  &__text {
    text-align: center;
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 48px;
  }
}
