@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
  font-family: 'Bookmania Semibold';
  src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.terms {
  background: $color-light-gray;
  height: 100vh;
  position: relative;

  &__info {
    max-width: 900px;
    margin-left: 200px;

    @include screen-md {
      margin-left: 0;
    }

    @include screen-sm {
      margin-top: 32px;
      padding-left: 32px;
      padding-right: 32px;
    }

    blockquote {
      overflow: hidden;
      padding-right: 1.5em;
      padding-left: 1.5em;
      margin-left: 0;
      margin-right: 0;
      font-style: italic;
      border-left: 5px solid #ccc;
    }

    .table {
      table {
        width: 100%;
      }

      td {
        border: 1px solid #dee2e6 !important;
      }
    }

    &-title {
      font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
      letter-spacing: -0.2px;
      font-size: 28px;
      margin-bottom: 24px;
    }

    &-subtitle {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      margin-bottom: 32px;
    }

    &-text {
      font-size: 16px;
      color: $color-font-gray;
      margin-bottom: 64px;
      white-space: pre-wrap;
    }
  }

  &__container {
    @include screen-xs {
      flex-direction: column;
    }
  }

  &__term {
    padding-top: 71px;
    background: $color-lightest-gray;
    border-radius: 0px;
    min-height: 378px;
    padding-left: 72px;
    padding-right: 72px;

    @include screen-sm {
      min-height: 159px;
      padding: 10px 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      margin-top: 105px;
      font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
      letter-spacing: -0.54px;
      margin-bottom: 16px;

      @include screen-sm {
        margin-top: 0;
      }
    }

    &-text {
      font-size: 18px;
      color: $color-font-gray;
      max-width: 581px;
    }
  }

  &__content {
    padding-top: 64px;
    background: $color-light-gray;
    padding-left: 72px;
    padding-right: 72px;

    @include screen-sm {
      padding: 0;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    min-width: 200px;

    &-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 23px;
      color: $color-brand-1;

      &:hover {
        color: $color-brand-1;
      }
    }

    @include screen-sm {
      display: none;
    }
  }

  &__popup {
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 214px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-title {
      font-size: 22px;
      font-weight: bold;
      color: $color-brand-1;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      max-width: 484px;
      text-align: center;
      margin-bottom: 24px;
    }

    &-highlighted {
      color: $color-brand-1;
    }

    &-accept {
      width: 96px !important;
      margin-right: 15px;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    &-download {
      background: transparent !important;
      color: $color-brand-1 !important;
      font-size: 14px !important;
      font-weight: bold !important;
      border: none !important;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }

  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  
}
