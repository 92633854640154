input,
select {
    background: #fff;
    border-radius: 5px !important;
    border: 1px solid $color-font-light !important;
    height: 38px !important;
    font-size: 14px !important;

    &::placeholder {
        color: $color-font-light !important;
    }

    &:focus {
        box-shadow: none !important;
        border: 1px solid $color-font-light !important;
    }
}

textarea {
    background: #fff;
    border-radius: 5px !important;
    border: 1px solid $color-font-light !important;
    resize: none !important;

    &::placeholder {
        color: $color-font-light !important;
    }

    &:focus {
        box-shadow: none !important;
        border-color: $color-font-light !important;
    }
}
