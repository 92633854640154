@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
	font-family: 'Bookmania Semibold';
	src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.request-access {
	&__container {
		padding-top: 71px;
		overflow: auto !important;
		background: $color-light-gray;
		padding-bottom: 71px;
		min-height: 800px;
	}

	&__check {
		border-radius: 4px;
		border: 1px solid $color-font-light;
		display: block;
		min-height: 13px;
		min-width: 13px;

		&.-is-active {
			background: $color-brand-1;
		}
	}

	&__item {
		background: #fff;
		border-radius: 4px;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		height: 59px;
		width: 280px;
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		font-size: 14px;
		font-weight: bold;
		color: #292e31;
		cursor: pointer;

		&-container {
			max-width: 632px;
			justify-content: space-between;

			@include screen-xs {
				flex-wrap: wrap;
			}
		}
	}

	&__email {
		margin-top: 24px;
		color: #292e31;
		font-size: 14px;

		a {
			color: $color-brand-1;

			&:hover {
				color: $color-brand-1;
			}
		}
	}

	&__header {
		background: $color-lightest-gray;
		min-height: 307px;
		padding-top: 105px;

		.container {
			@include screen-sm {
				display: flex;
				justify-content: center;
				flex-direction: column;
				height: 100%;
				min-height: 159px;
				padding-left: 32px;
				padding-right: 32px;
			}
		}

		@include screen-sm {
			padding-top: 10px;
			min-height: 159px;
		}

		&-title {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			letter-spacing: -0.54px;
			margin-bottom: 24px;
		}

		&-text {
			font-size: 16px;
			color: $color-font-gray;
		}
	}

	&__help {
		@include screen-sm {
			padding-left: 32px !important;
			padding-right: 32px !important;
		}

		&-title {
			margin-top: 48px;
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			font-size: 28px;
			margin-bottom: 16px;
		}

		&-text {
			font-size: 16px;
			color: #292e31;
			margin-bottom: 34px;
		}
	}

	&__form {
		max-width: 568px;

		textarea {
			min-height: 221px;
			font-size: 14px;
		}

		&-group {
			@include screen-sm {
				flex-wrap: wrap;

				input {
					margin-left: 0 !important;
                }
                
                .form-group {
                    margin-bottom: 24px !important;
                }
			}
		}
	}

	&__submit {
		float: right;
		margin-bottom: 30px;
	}
}
