@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.breach-sent-email {
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 369px !important;
    max-width: 693px !important;
    width: 693px !important;
    overflow: hidden;
    border-radius: 4px;

    .modal-content {
        height: 100% !important;
    }
    
    .modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.modal-signature {
	height: auto;
	max-width: 693px !important;
	width: 100% !important;
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
	min-height: 429px;

	.sigCanvas {
		border-color: $color-brand-1 !important;
		display: flex;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 47px;
	}

	&__file {
		background: #fff;
		border-radius: 4px;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		height: 59px;
		width: 280px;
		padding: 13px 10px;
		display: flex;
		align-items: center;
		margin-left: auto;
		margin-right: auto;

		&-type {
			border-radius: 0px;
			border: 1px solid rgb(223, 228, 238);
			box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
			min-height: 34px;
			min-width: 26px;
			font-size: 7px;
			font-weight: bold;
			color: $color-brand-4;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 16px;
		}

		&-name {
			margin-bottom: 0;
			font-size: 12px;
			color: $color-font-gray;
			width: 195px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-remove {
			cursor: pointer;
		}
	}

	&__clear {
		font-size: 14px;
		font-weight: bold;
		color: $color-brand-1;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	&__btns {
		margin-bottom: 40px;
		display: flex;
		justify-content: space-around;
	}

	&__title {
		font-size: 14px;
		color: $color-font-dark;
	}

	&__btn {
		max-width: 136px !important;

		&:focus {
			outline: none !important;
			box-shadow: none !important;
		}

		&.-is-disabled {
			color: #dfe4ee !important;
			border-color: #dfe4ee !important;
			background: #fff;

			&:hover {
				background-color: #fff !important;
			}
		}
	}

	.modal-content {
		height: 100%;
		min-height: 429px;
	}
}

.modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    overflow: hidden !important;
    border-radius: 4px;
}

.modal-assign {
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 378px;
    max-width: 693px !important;
    border-radius: 4px;

    &__text {
        margin-top: 42px;
        font-size: 14px;
        text-align: center;
        max-width: 423px;
        color: $color-font-gray;
        margin-bottom: 40px;
    }

    @include screen-md {
        max-width: 90% !important;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__close {
        display: flex;
        float: right;
        cursor: pointer;
        color: $color-font-gray;
        margin-left: auto;
    }

    &__image {
        margin-top: 31px;
        width: 119px;
        height: 119px;
    }

    &__title {
        margin-top: 11px;
        font-size: 22px;
        font-weight: bold;
        color: $color-brand-1;
        text-align: center;
        white-space: nowrap;
        margin-bottom: 0;
    }

    &__message {
        font-size: 14px;
        font-weight: normal;
        color: $color-font-gray;
        width: 43%;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 0;

        @include screen-sm {
            width: 80%;
        }
    }

    &__link {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
        color: $color-brand-1;

        &:hover {
            color: $color-brand-1;
        }
    }
}