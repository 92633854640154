.btn--primary,
.btn--secondary,
.btn--outline,
.btn--gray {
	font-size: 14px !important;
	font-weight: bold !important;
	border-radius: 4px !important;
	height: 40px;
	width: 223px;
}

.btn--primary {
	color: #fff !important;
	border: solid 0.5px $color-brand-1 !important;
	background-color: $color-brand-1 !important;

	&:hover,
	&.disabled:hover,
	&[disabled]:hover {
		color: #fff;
		background-color: darken($color-brand-1, 15%) !important;
		border-color: darken($color-brand-1, 15%) !important;
	}
}

.btn--secondary {
	color: $color-brand-1 !important;
	border: solid 0.5px $color-brand-1 !important;
	background-color: #fff !important;

	&:hover,
	&.disabled:hover,
	&[disabled]:hover {
		color: #fff;
		background-color: $color-lightest-gray !important;
		border-color: $color-brand-1 !important;
	}
}

.-is-contained {
	border-radius: 4px;
	min-height: 36px;
	min-width: 136px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
}

.btn--outline {
	color: $color-brand-1 !important;
	border: none !important;
	background-color: #fff !important;

	&:hover,
	&.disabled:hover,
	&[disabled]:hover {
		color: #fff;
		background-color: #fff !important;
		border-color: none !important;
	}
}

.btn--gray {
	color: $color-brand-1 !important;
	border: none !important;
	background-color: $color-light-gray !important;

	&:hover,
	&.disabled:hover,
	&[disabled]:hover {
		color: #fff;
		background-color: darken($color-light-gray, 5%) !important;
		border-color: none !important;
	}
}
