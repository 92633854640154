@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
	font-family: 'Bookmania Semibold';
	src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.footer {
	&__container {
		padding-left: 72px;
		padding-right: 72px;
		margin-left: auto;
		margin-right: auto;
		justify-content: space-around;
		padding-top: 49px;
		padding-bottom: 49px;
		background: #dfe4ee;

		@include screen-sm {
			padding-left: 33px;
			padding-right: 33px;
		}
	}

	&__logo {
		@include screen-sm {
			margin-bottom: 32px;
		}
	}

	&__col {
    @include screen-sm {
      margin-bottom: 30px;
    }
	}

	p {
		font-size: 14px;
	}

	a {
		color: $color-font-base;
		font-size: 14px;

		&:hover {
			color: $color-font-base;
			text-decoration: none;
		}
	}

	&-text {
		border-top: 1px solid #969696;
		text-align: center;
		padding-top: 26px;
		padding-bottom: 26px;
		background: #dfe4ee;
    font-size: 14px;
    
    @include screen-sm {
      margin-left: 32px;
      margin-right: 32px;
      text-align: left;
    }
	}

	&-get {
		color: $color-brand-1;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}
