// mixins for media queries
@mixin screen-lg {
  @media (max-width: $media-lg) {
    @content;
  }
}

@mixin screen-md {
  @media (max-width: $media-md) {
    @content;
  }
}

@mixin screen-sm {
  @media (max-width: $media-sm) {
    @content;
  }
}

@mixin screen-xs {
  @media (max-width: $media-xs) {
    @content;
  }
}

// mixin for inserting retina images
@mixin retina-img($file, $repeat, $width, $height, $left, $top) {
  @media only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    background-image: url("#{$file}");
    background-position: $left $top;
    background-size: $width $height;
    background-repeat: $repeat;
  }
}