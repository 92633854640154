@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
	font-family: 'Bookmania Semibold';
	src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.dpo {
	display: flex;
	flex-direction: column;
	height: 100vh;
	background: $color-light-gray;

	&__container {
		margin-top: 72px;
	}

	&__page-content {
		background: rgb(255, 255, 255);
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		padding-top: 40px;
		margin-top: -30px;
		margin-bottom: 30px;
		margin-left: 72px;
		width: 90%;
		max-width: 1200px;
		padding-bottom: 40px;

		@include screen-sm {
			width: calc(100% - 64px);
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__header {
		min-height: 307px;
		background: $color-lightest-gray;
		padding-left: 72px;
		padding-right: 72px;
		padding-top: 60px;

		@include screen-sm {
			min-height: 202px;
			padding-top: 10px;
			padding-left: 32px;
			padding-right: 32px;
		}

		&-title {
			font-size: 56px;
			letter-spacing: -0.54px;
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			margin-bottom: 24px;
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
		}
	}

	&__title {
		padding-left: 72px;

		@include screen-sm {
			padding-left: 16px;
		}
	}

	&__impressum {
		&-info {
			padding-left: 72px;

			@include screen-sm {
				padding-left: 16px;
			}
		}

		&-title {
			width: 300px;

			@include screen-sm {
				width: 48%;
				flex-wrap: wrap;
				display: flex;
			}
		}

		&-text {
			@include screen-sm {
				width: 48%;
				flex-wrap: wrap;
				display: flex;
				word-break: break-all;
			}
		}
	}

	&__content {
		padding-left: 72px;
		padding-right: 72px;
		display: flex;

		@include screen-sm {
			padding-left: 32px;
			padding-right: 32px;
			flex-direction: column;
		}
	}

	&__divider {
		width: 90%;
		height: 1px;
		background: #dfe4ee;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
	}

	&__avatar {
		margin-right: 123px;
		min-width: 160px;
		width: 160px;
		height: 160px;
		min-height: 160px;
		background: $color-font-gray;
		border-radius: 50%;
		position: relative;
		overflow: hidden;
		top: 40px;

		@include screen-sm {
			margin-bottom: 20px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__info {
		margin-top: 54px;
	}

	&__name {
		letter-spacing: -0.2px;
		font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
		font-size: 28px;
		line-height: 32px;
		margin-bottom: 24px;
		max-width: 600px;
		white-space: pre-wrap;
	}

	&__description {
		font-size: 14px;
		color: $color-font-gray;
		margin-bottom: 44px;
		max-width: 600px;
		white-space: pre-wrap;

		a {
			color: $color-brand-1;

			&:hover {
				color: $color-brand-1;
			}
		}
	}
}
