@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
	font-family: 'Bookmania Semibold';
	src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.requests {
	&__container {
		padding-top: 71px;
		background: $color-light-gray;

		.container {
			@include screen-sm {
				padding-left: 32px;
				padding-right: 32px;
			}
		}
	}

	&__header {
		background: $color-lightest-gray;
		min-height: 307px;
		padding-bottom: 10px;
		padding-top: 105px;

		.container {
			@include screen-sm {
				padding-left: 32px;
				padding-right: 32px;
			}
		}

		@include screen-sm {
			min-height: 237px;
			padding-top: 10px;
		}

		&-title {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			letter-spacing: -0.54px;
			margin-bottom: 24px;
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
			width: 60%;

			@include screen-sm {
				width: 100%;
			}
		}
	}

	&__request-container {
		background: #fff;
		border-radius: 4px;
		min-height: 352px;
		max-width: 716px;
		width: 100%;
		margin-top: 68px;

		@include screen-sm {
			margin-top: 32px;
		}
	}

	&__learn {
		background: #fff;
		border-radius: 4px;
		box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.06);
		min-height: 517px;
		width: 340px;
		margin-top: -100px;
		padding: 34px;
		z-index: 10;

		@media (max-width: 1199px) {
			margin-top: 20px;
		}

		&-title {
			font-size: 22px;
			font-weight: bold;
			letter-spacing: -0.16px;
			color: $color-brand-1;
			margin-bottom: 24px;
			text-align: center;
		}

		&-text {
			font-size: 14px;
			color: $color-font-dark;
			margin-bottom: 47px;
		}

		&-btn {
			display: flex;
			justify-content: center;
			margin-bottom: 36px;
		}

		&-img {
			z-index: 1;
		}
	}

	&__banner {
		height: 150px;
		background: linear-gradient(45deg, rgb(106, 69, 192) 0%, rgb(156, 120, 241) 100%);
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
		z-index: -1;
	}
}

.thanks {
	height: 374px;
	max-width: 1164px;
	background: #fff;
	width: 100%;
	border-radius: 4px;
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: relative;
	top: -160px;

	&__img {
		margin-bottom: 32px;
	}

	&__title {
		font-size: 22px;
		font-weight: bold;
		letter-spacing: -0.16px;
		color: $color-brand-1;
		margin-bottom: 24px;
	}

	&__text {
		font-size: 14px;
		color: $color-font-dark;
	}
}

.inbox {
	&__create {
		font-size: 14px;
		padding-right: 40px;

		@include screen-sm {
			margin-right: 0;
			padding-right: 0;
		}

		&-title {
			font-size: 22px;
			letter-spacing: -0.16px;
			padding-top: 24px;
			margin-bottom: 24px;
			padding-left: 40px;
			padding-right: 40px;
		}

		&-check {
			border-radius: 4px;
			border: 1px solid $color-font-light;
			display: block;
			height: 13px;
			width: 13px;
			min-width: 13px;
			min-height: 13px;

			&.-is-active {
				background: $color-brand-1;
			}
		}
	}

	&__internal {
		&-file {
			background: #fff;
			border-radius: 4px;
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			height: 59px;
			width: 280px;
			padding: 13px 10px;
			display: flex;
			align-items: center;
			margin-top: 36px;

			&-type {
				border-radius: 0px;
				border: 1px solid rgb(223, 228, 238);
				box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
				min-height: 34px;
				min-width: 26px;
				font-size: 7px;
				font-weight: bold;
				color: $color-brand-4;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 16px;
			}

			&-name {
				margin-bottom: 0;
				font-size: 12px;
				color: $color-font-gray;
				width: 195px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&-remove {
				cursor: pointer;
			}
		}
	}

	&__data-stats {
		display: flex;
		justify-content: space-between;
		padding: 32px 82px;
		border-bottom: 1px solid $color-border;

		@include screen-sm {
			padding: 24px 0;
			margin: 0 24px;
			flex-direction: column;

			> div {
				margin-bottom: 24px;
			}
		}

		&-label {
			font-size: 14px;
			font-weight: 600;
			color: $color-font-base;
		}

		&-value {
			font-size: 14px;
			color: $color-font-dark;
		}

		&-btn {
			min-width: 136px;
			max-width: 136px;
		}
	}

	&__data-actions {
		padding: 32px 82px;

		&-btns {
			@include screen-sm {
				flex-direction: column;
			}
		}

		@include screen-sm {
			padding: 24px;
		}

		&-solve {
			width: 136px !important;

			@include screen-sm {
				margin-bottom: 16px;
			}
		}
	}

	&__data-user {
		background: #fff;
		padding: 31px 35px 35px 15px;
		border-bottom: 1px solid $color-border;

		@include screen-sm {
			padding: 24px 0;
			margin: 0 24px;
		}

		&.-has-company {
			padding-left: 80px;
		}

		&-status {
			width: 8px;
			height: 8px;
			display: block;
			border-radius: 50%;
			margin-right: 16px;

			&.-is-purple {
				background: $color-brand-1;
			}

			&.-is-coral {
				background: $color-brand-5;
			}

			&.-is-aqua {
				background: $color-brand-4;
			}

			&.-is-yellow {
				background: $color-brand-3;
			}

			&.-is-black {
				background: #dfe4ee;
			}
		}

		&-avatar {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 16px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&-placeholder {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				overflow: hidden;
				margin-right: 16px;
				background: #b0bac9;
				display: block;
			}
		}

		&-name {
			font-size: 18px;
			color: $color-font-base;
		}

		&-date {
			display: none;
		}

		&-email,
		&-date {
			font-size: 12px;
			color: $color-font-gray;
		}

		&-action {
			cursor: pointer;
			&.-user {
				background: $color-light-gray;
				display: flex;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				padding: 5px;
			}
		}
	}

	&__solve {
		@include screen-sm {
			&-actions {
				flex-direction: column;

				.btn {
					margin-bottom: 20px;
				}
			}
		}
	}
}
