@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
	font-family: 'Bookmania Semibold';
	src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.page-view {
	background: rgb(255, 255, 255);
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
	min-height: 603px;
	max-width: 537px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
	margin-bottom: 32px;

	&__content {
		display: flex;
		justify-content: space-between;
		padding-top: 30px;
		background: $color-light-gray;
		height: 100%;
		width: 100%;
	}

	&__pages-container {
		overflow: scroll;

		blockquote {
      overflow: hidden;
      padding-right: 1.5em;
      padding-left: 1.5em;
      margin-left: 0;
      margin-right: 0;
      font-style: italic;
      border-left: 5px solid #ccc;
    }

    .table {
      table {
        width: 100%;
      }

      td {
        border: 1px solid #dee2e6 !important;
      }
    }
	}

	&__pages {
		display: flex !important;
		flex-wrap: wrap !important;
		min-height: 70px !important;
		align-items: center !important;
		background: #f4f6fc;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;

		&-title {
			flex: 0 50%;
			font-size: 10px;
			color: $color-brand-1;
			font-weight: bold;
			text-align: center;
			margin-bottom: 0;
			margin-left: 0 !important;
			cursor: pointer;
		}
	}

	&__close {
		cursor: pointer;
		z-index: 99;
	}

	&__container {
		overflow: hidden;
		height: 100%;
		background: $color-light-gray;

		img {
			width: 100%;
		}
	}

	&__left-menu {
		padding-left: 50px;
		width: 30%;

		@include screen-sm {
			display: none;
		}

		&-item {
			margin-bottom: 5px;
			color: $color-brand-1;
			font-size: 10px;
			font-weight: bold;
		}
	}

	&__publication {
		width: 70%;
		padding-right: 30px;

		@include screen-sm {
			width: 100%;
			padding: 0 20px;
		}

		&-title {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			font-size: 12px;
			font-weight: 600;
			letter-spacing: -0.16px;
			margin-bottom: 8px;
		}

		&-text {
			font-size: 10px;
			margin-bottom: 20px;
			color: $color-font-gray;
		}
	}

	&__close {
		color: $color-font-gray;
		position: absolute;
		right: 15px;
		top: 15px;
	}

	&__header {
		background: $color-lightest-gray;
		min-height: 120px;
		padding-left: 50px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: relative;
		margin-left: 0 !important;
		margin-right: 0 !important;

		@include screen-sm {
			padding-left: 20px;
		}
	}

	&__title {
		font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
		font-size: 22px;
		font-weight: 600;
		letter-spacing: -0.16px;
		margin-bottom: 8px;
	}

	&__subtitle {
		font-size: 10px;
		color: $color-font-gray;
		margin-bottom: 0;
	}
}

.customization {
	&-cookie {
		width: 100%;

		&__input-text {
			max-width: 624px;
			margin-left: auto;
			margin-right: auto;
		}

		&__view {
			width: 921px;
			height: 60%;
			max-height: 600px;
			min-height: 600px !important;
			padding: 0 !important;
			margin: 0 !important;

			@include screen-sm {
				margin-left: auto !important;
				margin-right: auto !important;
			}

			.page-view__header {
				text-align: left;
			}

			.page-view__left-menu {
				text-align: left;
			}

			.page-view__publication {
				text-align: left;
			}
		}

		&__vertical {
			max-width: 100% !important;
		}

		&__overlay {
			width: 100%;
		}

		&__popup-text {
			max-width: 624px;
		}

		&__avatar-container {
			box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
			height: 63px;
			min-width: 63px;
			min-height: 63px;
			width: 63px;
			display: block;
			border-radius: 50%;
			cursor: pointer;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__template-img {
			position: absolute;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			top: 0;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
			pointer-events: none;
		}

		&__image-placeholder {
			background: #b0bac9;
			box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
			height: 72px;
			width: 72px;
			display: block;
			border-radius: 50%;
			cursor: pointer;
		}

		&__title {
			font-size: 22px;
			font-weight: bold;
			margin-bottom: 64px;
		}

		&__subtitle {
			font-size: 14px;
			color: $color-font-gray;
			margin-bottom: 48px;
		}

		&__input {
			max-width: 223px;
			margin-bottom: 24px;

			&-large {
				max-width: 568px;
				margin-bottom: 24px;
				width: 100% !important;
			}
		}

		&__textarea {
			min-height: 221px;
			max-width: 568px;
		}

		&__bg-screen {
			border: 1px solid rgb(176, 186, 201);
			box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
			height: 29px;
			width: 29px;
			display: block;
			border-radius: 50%;

			&.-white {
				background: #fff;
			}

			&.-gray {
				background: $color-font-gray;
			}
		}

		&__container {
			display: flex;
			flex-direction: column;
			flex: 1;
			height: calc(100vh - 90px);
			overflow: auto;
			padding-bottom: 40px;
			padding-right: 20px;

			&.-is-sample {
				height: calc(100vh - 142px);
			}
		}

		&__right-menu {
			right: 0;
			background: #fff;
			border-radius: 1px;
			border: 1px solid rgba(46, 91, 255, 0.08);
			top: 90px;
			height: calc(100vh - 90px);
			overflow: auto;
			max-width: 310px;
			min-width: 310px;
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			padding-top: 48px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: -48px;

			&.-is-sample {
				height: calc(100vh - 142px);
			}

			@include screen-md {
				min-width: 210px;
			}

			&-snippet {
				margin-top: 17px;
				padding-left: 44px;

				@include screen-md {
					padding-left: 10px;
				}
			}

			&-logo {
				width: 63px;
				height: 63px;
				border-radius: 50%;
				box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&-change {
				font-size: 14px;
				font-weight: 600;
				color: $color-font-gray;
				margin-left: 16px;
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}

			&-label {
				font-size: 14px;
				font-weight: bold;
				color: $color-font-gray;
				margin-bottom: 11px;
			}

			&-btns {
				margin-top: 48px;
				margin-bottom: 20px;

				.btn {
					width: 136px !important;

					@include screen-md {
						margin-bottom: 10px;
					}
				}

				@include screen-md {
					flex-direction: column;
				}
			}

			&-bg,
			&-btn {
				border: 1px solid #979797;
				box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
				height: 29px;
				width: 29px;
				display: block;
				border-radius: 50% !important;
			}

			&-bg {
				background: #fafafa;
			}

			&-btn {
				background: $color-brand-1;
			}

			&-option {
				margin-left: 44px;
				margin-bottom: 12px;

				&:first-child {
					margin-left: 0;
				}
			}

			&-content {
				padding-left: 44px;
				margin-right: auto;

				@include screen-md {
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}

		&__preview {
			margin-bottom: 16px;
		}

		&__preview-mail {
			margin-bottom: 32px;
			background-color: $color-light-gray !important;
			border-radius: 4px !important;
			height: 40px !important;
			width: 223px !important;
			border: none !important;
			color: $color-brand-1 !important;
			font-weight: bold !important;
			font-size: 14px !important;
		}

		.check {
			border-radius: 4px;
			border: 1px solid $color-font-light;
			display: block;
			min-height: 13px;
			min-width: 13px;
			max-height: 13px;
			max-width: 13px;
			margin-right: 8px;

			&.-is-active {
				background: $color-brand-1;
			}
		}

		&__text {
			font-size: 14px;
			font-weight: bold;
			color: $color-font-gray;
			width: 180px;
		}

		&__check {
			position: relative;
			top: -10px;
			right: 13px;

			&-item {
				font-size: 14px;
				color: $color-font-gray;
				margin-right: 48px;
				width: 90px;
			}

			&-container {
				margin-bottom: 32px;
			}
		}

		input {
			width: 223px;
			margin-top: 8px;
			margin-bottom: 24px;
		}

		textarea {
			margin-top: 8px;
			min-height: 221px !important;
			margin-bottom: 20px;
			max-width: 568px;
		}

		&__img {
			cursor: pointer;

			&.-is-active {
				border-radius: 4px;
				border: 2px solid $color-brand-4;
				box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
				height: 154px;
				width: 212px;
			}
		}

		&__item {
			margin-bottom: 48px;
		}

		&__next {
			position: relative;
			top: -50px;
			margin-left: 40px;
			margin-top: 60px;
		}

		&__small,
		&__vertical,
		&__overlay {
			background: rgb(255, 255, 255);
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			padding: 24px 16px 12px 16px;
			margin-bottom: 0;
		}

		&__page-view {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding: 10px;

			span {
				display: flex;
				width: 100%;
				justify-content: flex-end;
			}
		}

		&__overlay {
			max-width: 525px;
			min-width: 525px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			max-width: 681px;

			@include screen-sm {
				max-width: calc(100% - 64px);
				min-width: calc(100% - 64px);
				margin-left: auto;
				margin-right: auto;
			}

			.customization-cookie__get {
				margin-left: auto;
				top: 0;
			}

			> div {
				p {
					width: 100%;
					margin-left: auto;
					margin-right: auto;
				}

				div {
					margin-left: auto;
					margin-right: auto;
				}
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}

		&__vertical {
			max-width: 644px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			.customization-cookie__get {
				margin-left: auto;
				top: 0;
			}

			> div {
				p {
					width: 100%;
					margin-left: auto;
					margin-right: auto;
				}

				div {
					margin-left: auto;
					margin-right: auto;
				}
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}

		&__small {
			width: 280px;
		}

		&__label {
			font-size: 14px;
			font-weight: bold;
			color: $color-font-gray;
			width: 100px;
			margin-right: 27px;

			&-secondary {
				font-size: 14px;
				color: $color-font-gray;
				width: 100px;
				margin-right: 27px;
			}
		}

		&__popup-title {
			font-size: 22px;
			color: $color-brand-1;
			margin-bottom: 24px;
		}

		&__popup-text {
			font-size: 14px;
			color: $color-font-gray;
			margin-bottom: 24px;
		}

		&__popup-accept,
		&__popup-options {
			min-height: 36px !important;
			padding: 15px !important;
		}

		&__popup-accept {
			margin-right: 16px;
			// font-weight: bold !important;
			border-radius: 4px !important;
			border: none !important;
		}

		&__popup-options {
			background: transparent !important;
			border: none !important;
			font-weight: bold !important;
		}

		&__get {
			position: relative;
			top: 5px;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.popup {
	&__cookie-close {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}

	&__cookie-popup {
		position: fixed;
		bottom: 51px;
		z-index: 99;

		@include screen-sm {
			bottom: 20px;
		}

		.btn-round {
			border-radius: 30px !important;
		}

		&.-is-white,
		&.-is-gray {
			position: fixed;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 99;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}

		&.-is-white {
			background-color: rgba(255, 255, 255, 0.73);

			.customization-cookie__overlay {
				width: 100%;
			}
		}

		&.-is-gray {
			background-color: rgba(0, 0, 0, 0.73);
		}

		&.-is-left {
			left: 48px;

			@include screen-sm {
				left: 20px;
			}
		}

		&.-is-right {
			right: 48px;
		}

		&.-is-bottom {
			bottom: 0px;
			width: 100%;

			.customization-cookie__vertical {
				max-width: 100% !important;
			}

			.customization-cookie__popup-text {
				max-width: 624px;
			}
		}

		&.-is-top {
			top: 72px;
			width: 100%;
			bottom: auto;

			.customization-cookie__vertical {
				max-width: 100% !important;
			}

			.customization-cookie__popup-text {
				max-width: 624px;
			}
		}
	}

	.btn-round {
		border-radius: 30px !important;
	}

	&__container {
		background: $color-light-gray;
		height: calc(100vh - 77px);
		overflow: hidden;

		&.-has-overflow {
			overflow: auto;
		}
	}

	&__header {
		box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
		height: 77px;
		display: flex;
		background: rgb(176, 186, 201);
		align-items: center;
		justify-content: center;
		position: relative;
	}

	&__close-btn {
		position: absolute;
		right: 91px;
		color: #fff;
		font-size: 12px;
		font-weight: bold;
		align-items: center;
		justify-content: center;
		display: flex;
		cursor: pointer;

		@include screen-xs {
			right: 10px;
		}
	}

	&__title {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 0;
		color: #fff;
	}

	&__welcome-section {
		height: 430px;
		background: $color-lightest-gray;
	}

	&__info {
		&-privacy {
			background: #fff;
			border-radius: 4px;
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			min-height: 258px;
			max-width: 219px !important;
			margin-right: 172px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			text-align: center;

			&:last-child {
				margin-right: 0;
			}

			svg {
				margin-top: 32px;
				min-height: 31px;
				margin-bottom: 25px;
			}

			@include screen-md {
				margin-right: 20px;
				margin-top: 30px;
			}

			@include screen-xs {
				margin-right: 10px;
				margin-left: 10px;
			}
		}

		&-subtitle {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			font-size: 28px;
			letter-spacing: -0.2px;
			text-align: center;
			margin-top: 90px;
			margin-bottom: 24px;
		}

		&-details {
			font-size: 18px;
			color: $color-font-gray;
			max-width: 695px;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 67px;
			padding-left: 10px;
			padding-right: 10px;

			&-item {
				width: 163px;
				text-align: center;
				margin-left: 72px;
				margin-right: 72px;
			}
		}

		&-title {
			font-size: 18px;
			font-weight: 600;
			color: $color-brand-1;
			margin-bottom: 24px;
		}

		&-text {
			font-size: 16px;
			color: $color-font-gray;
			margin-bottom: 21px;
		}

		&-more {
			font-size: 14px;
			font-weight: bold;
			color: $color-brand-1;
		}

		&-content {
			justify-content: center;
			top: -140px;
			position: relative;
			margin-bottom: -140px;
		}
	}

	&__welcome {
		&-title {
			font-size: 56px;
			font-weight: 600;
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			letter-spacing: -0.54px;
			text-align: center;
			padding-top: 70px;
			margin-bottom: 24px;
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
			text-align: center;
			max-width: 470px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}
